import { CC_FAMILY, CC_STATUS, EBillingShipment, EPlanningType, ESlot } from '@/types/global';
import { EBillingBundle, EBillingMode, ETrashType, EUserRole } from '@/types/global';

export function mapTrashTypetext(family?: ETrashType): string {
	switch (family) {
		case ETrashType.ASBESTOS: {
			return 'Amiante';
		}
		case ETrashType.GLASS: {
			return 'Verre';
		}
		case ETrashType.LEAD: {
			return 'Plomb';
		}
		case ETrashType.METAL: {
			return 'Métal';
		}
		case ETrashType.MINERAL_FRACTION: {
			return 'Fraction minérale';
		}
		case ETrashType.MIXED: {
			return 'Mélangé';
		}
		case ETrashType.PAPER_CARDBOARD: {
			return 'Papier/carton';
		}
		case ETrashType.PLASTER: {
			return 'Plâtre';
		}
		case ETrashType.PLASTIC: {
			return 'Plastique';
		}
		case ETrashType.RUBBLE: {
			return 'Gravat';
		}
		case ETrashType.WOOD: {
			return 'Bois';
		}
		case ETrashType.OIW: {
			return 'DIB';
		}
		case ETrashType.GREEN_TRASH: {
			return 'Vert';
		}
		case ETrashType.ULTIMATE_TRASH: {
			return 'Ultime';
		}
		default: {
			return 'Type inconnu';
		}
	}
}

export function mapRoleText(role: EUserRole): string {
	switch (role) {
		case EUserRole.BBNG_USER_ADMIN: {
			return 'Administrateur';
		}
		case EUserRole.BBNG_USER_MANAGER: {
			return 'Manager';
		}
		case EUserRole.BBNG_USER_ACCOUNTANT: {
			return 'Comptable';
		}
		case EUserRole.BBNG_USER_EMPLOYEE: {
			return 'Employé';
		}
		case EUserRole.BBNG_USER_CUSTOM: {
			return 'Personnalisé';
		}
		default: {
			return 'Rôle inconnu';
		}
	}
}

export function mapBillingModeText(billingMode?: EBillingMode): string {
	switch (billingMode) {
		case EBillingMode.INSTANT: {
			return 'À la commande';
		}
		case EBillingMode.AFTER_COLLECT: {
			return 'Après collecte';
		}
		case EBillingMode.END_OF_MONTH: {
			return 'Fin de mois';
		}
		default: {
			return 'Mode de facturation inconnu';
		}
	}
}

export function mapBillingBundleText(billingBundle?: EBillingBundle): string {
	switch (billingBundle) {
		case EBillingBundle.BY_COLLECT: {
			return 'Par collecte';
		}
		case EBillingBundle.BY_CONSTRUCTION_SITE: {
			return 'Par chantier';
		}
		case EBillingBundle.GLOBAL: {
			return 'Global';
		}
		case EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE: {
			return 'Global par chantier';
		}
		default: {
			return 'Regroupement inconnu';
		}
	}
}

export function mapBillingShipment(billingShipment?: EBillingShipment): string {
	switch (billingShipment) {
		case EBillingShipment.EMAIL: {
			return 'Par email';
		}
		case EBillingShipment.LETTER: {
			return 'Par courrier';
		}
		default: {
			return "Mode d'envoi inconnu";
		}
	}
}

export function mapTypeToText(type: EPlanningType): string {
	switch (type) {
		case EPlanningType.BIG_BAG: {
			return 'Big bag';
		}
		case EPlanningType.DUMPSTER: {
			return 'Benne';
		}
		default: {
			return 'Type inconnu';
		}
	}
}

export function mapCCFamilyToText(family: CC_FAMILY): string {
	switch (family) {
		case CC_FAMILY.COLLECT_BIG_BAG: {
			return 'Collecte big bag';
		}
		case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT: {
			return 'Dépôt';
		}
		case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT: {
			return 'Attente chargement benne';
		}
		case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL: {
			return 'Enlèvement';
		}
		case CC_FAMILY.COLLECT_DUMPSTER_ROTATION: {
			return 'Rotation';
		}
		case CC_FAMILY.DELIVERY_BIG_BAG: {
			return 'Livraison big bag';
		}
		default: {
			return 'Famille inconnue';
		}
	}
}

export function mapSlotToText(slot: ESlot): string {
	switch (slot) {
		case ESlot.FIVE_EIGHT_AM: {
			return '5h-8h';
		}
		case ESlot.EIGHT_TEN_AM: {
			return '8h-10h';
		}
		case ESlot.TEN_TWELVE_AM: {
			return '10h-12h';
		}
		case ESlot.TWELVE_TWO_PM: {
			return "12h-14h";
		}
		case ESlot.TWO_FOUR_PM: {
			return "14h-16h";
		}
		case ESlot.FOUR_SEVEN_PM: {
			return '16h-19h';
		}
		case ESlot.DAY: {
			return '5h-19h';
		}
		case ESlot.MORNING: {
			return '5h-12h';
		}
		case ESlot.AFTERNOON: {
			return '12h-19h';
		}
		default: {
			return 'Créneau inconnu';
		}
	}
}

export function mapSlotTextToApi(slot: ESlot): string /* HH:mm-HH:mm */ {
	switch (slot) {
		case ESlot.FIVE_EIGHT_AM: {
			return '05:00-08:00';
		}
		case ESlot.EIGHT_TEN_AM: {
			return '08:00-10:00';
		}
		case ESlot.TEN_TWELVE_AM: {
			return '10:00-12:00';
		}
		case ESlot.TWELVE_TWO_PM: {
			return '12:00-14:00';
		}
		case ESlot.TWO_FOUR_PM: {
			return '14:00-16:00';
		}
		case ESlot.FOUR_SEVEN_PM: {
			return '16:00-19:00';
		}
		case ESlot.DAY: {
			return '05:00-19:00';
		}
		case ESlot.MORNING: {
			return '05:00-12:00';
		}
		case ESlot.AFTERNOON: {
			return '12:00-19:00';
		}
		default: {
			return '00:00-00:00';
		}
	}
}

export function mapCollectStatusToText(status: CC_STATUS): string {
	switch (status) {
		case CC_STATUS.FINISHED: {
			return 'Terminée';
		}
		case CC_STATUS.CANCELED: {
			return 'Annulée';
		}
		case CC_STATUS.HAZARD: {
			return 'Aléa';
		}
		case CC_STATUS.ORDER_TO_PAY: {
			return 'Commande à payer';
		}
		case CC_STATUS.PLANNED: {
			return 'Planifiée';
		}
		case CC_STATUS.TO_PLAN: {
			return 'A planifier';
		}
		default: {
			return 'Statut inconnu';
		}
	}
}
