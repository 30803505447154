import { NewVersionModal } from '@/components/new-version-modal';
import { Button } from '@/components/ui/button';

import { environmentVar as environmentVariable } from '../../env';

export const MaintenancePage = ({ lastVersionData }: { lastVersionData?: string | null }) => {

	function clearAllCacheAndReload() {
		// Clear localStorage
		if (window.localStorage) {
			window.localStorage.clear();
			console.log('LocalStorage cleared');
		}

		// Clear sessionStorage
		if (window.sessionStorage) {
			window.sessionStorage.clear();
			console.log('SessionStorage cleared');
		}

		// Clear all cookies (if needed)
		for (const cookie of document.cookie.split(";")) {
			const name = cookie.split("=")[0];
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
		}
		console.log('Cookies cleared');

		// Clear Service Workers (if any)
		if (navigator.serviceWorker) {
			navigator.serviceWorker.getRegistrations().then(function(registrations) {
			for (const registration of registrations) {
				registration.unregister();
				console.log('ServiceWorker unregistered');
			}
			});
		}

		// Clear the entire cache storage (if supported)
		if (window.caches) {
			caches.keys().then(function(cacheNames) {
				for (const cacheName of cacheNames) {
					caches.delete(cacheName);
					console.log(`Cache ${cacheName} cleared`);
				}
			});
		}

		// Reload the page and force the browser to fetch everything fresh from the server
		window.location.reload();
	}

	if (lastVersionData && lastVersionData !== environmentVariable.VITE_APP_VERSION) {
		return <NewVersionModal open={true} onOpenChange={() => {}} lastVersion={lastVersionData ?? ''} />;
	}

	return (
		<div className="flex flex-col items-center justify-center h-screen w-full">
			<h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">Maintenance</h1>
			<p className="mt-4 text-left text-gray-600 dark:text-gray-400 w-4/5 text-wrap ">
				Cher visiteur,
				<br />
				<br />
				Nous sommes actuellement en train de peaufiner quelques détails pour améliorer votre expérience sur notre site.
				Nous nous excusons pour la gêne occasionnée. Soyez assuré que nous travaillons sans relâche pour revenir en
				ligne le plus rapidement possible.
				<br />
				<br />
				Votre patience et votre compréhension sont grandement appréciées pendant cette période de maintenance. Nous
				avons hâte de vous retrouver bientôt avec une plateforme encore plus performante et enrichissante.
				<br />
				<br />À très bientôt !
				<br />
				<br />
				<div className="flex items-center justify-end">L'équipe Endless.</div>
			</p>
			{lastVersionData && lastVersionData === environmentVariable.VITE_APP_VERSION && (
				<div className="mt-12 flex flex-col justify-center items-center border border-slate-500 rounded-md p-4 text-center">
					<p className="text-center text-slate-600">
						Il semblerait que la maintenance soit terminée. Essayez de cliquer sur ce bouton. Si le problème persiste, n’hésitez pas à nous contacter.
					</p>
					<Button className='mt-4' onClick={() => clearAllCacheAndReload()}>
						Recharger la page
					</Button>
				</div>
			)}
			
		</div>
	);
};
