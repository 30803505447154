import { PlusCircle } from 'lucide-react';
import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

import LOGIN_LOGO from '@/assets/endless_logo_black.png';
import { SuspenseWrapper } from '@/components/suspense';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { Link } from '@tanstack/react-router';

import { navbarData, navbarDataMobile } from '../data/navbar';
import { useHeader } from '../hooks/header';

import { ClientName } from './client-name';

const LOGO_LINK = 'https://endless.fr';

type HeaderProperties = {
	displayLogo: boolean;
	displayMenu: boolean;
	displayOrderButton: boolean;
	displayClientName: boolean;
	displayDrpdown: boolean;
};

export const Header: React.FC<HeaderProperties> = ({
	displayLogo,
	displayMenu,
	displayOrderButton,
	displayClientName,
	displayDrpdown,
}) => {
	const { data, utils, requests, drp } = useHeader();

	const isDesktop = useMediaQuery('(min-width: 768px)');

	return (
		<header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 flex items-center justify-center">
			<div className="w-full flex items-center justify-between p-4 max-w-screen-2xl">
				<div className="flex items-center justify-center gap-3">
					{displayLogo && (
						<div
							className="cursor-pointer"
							onClick={(event) => {
								event.preventDefault();
								event.preventDefault();

								//Go to endless.fr.
								window.location.href = LOGO_LINK;
							}}
						>
							<img src={LOGIN_LOGO} alt="Endless Logo" className="w-32 mr-3" loading="lazy" />
						</div>
					)}
					{isDesktop && displayMenu && (
						<>
							{navbarData.map((item, index) => {
								return (
									<Link
										from="/"
										key={index}
										to={item.to as any}
										className="text-sm text-slate-400 hover:text-slate-500"
										activeProps={{
											className: 'font-semibold text-slate-800 underline',
										}}
										activeOptions={{ exact: false, includeHash: true, includeSearch: false }}
										search={{
											...(item.search as any),
										}}
									>
										{item.label}
									</Link>
								);
							})}
						</>
					)}
				</div>
				{/* <div className="flex items-center justify-center gap-9"> */}
				<div className={cn('flex items-center justify-center', isDesktop ? 'gap-9' : 'gap-3')}>
					{displayOrderButton && (
						<Link
							from="/"
							to={'/dashboard/orders/new/'}
							resetScroll={true}
							className={cn(
								'p-3 rounded-sm text-sm text-white font-semibold flex items-center justify-center gap-1 bg-[#5465FC] hover:bg-[#2238fc] hover:cursor-pointer',
								// location.pathname.includes('/dashboard/orders/new/') ? 'hidden' : '',
							)}
						>
							<PlusCircle size={16} />
							Commander
						</Link>
					)}
					{displayClientName && (
						<SuspenseWrapper
							component={<ClientName />}
							loader={<div className="w-32 h-6 rounded animate-pulse bg-gray-200" />}
						/>
					)}
					{displayDrpdown && (
						<DropdownMenu open={drp.value} onOpenChange={drp.onChange}>
							<DropdownMenuTrigger asChild>
								<Button variant="ghost" className="relative h-8 w-8 rounded-full">
									<Avatar>
										<AvatarFallback>
											{utils.getInitials(data.me?.firstname || '', data.me?.lastname || '')}
										</AvatarFallback>
									</Avatar>{' '}
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="w-56" align="end" forceMount>
								<DropdownMenuLabel className="font-normal">
									{isDesktop === false && displayClientName && (
										<>
											<SuspenseWrapper
												component={<ClientName />}
												loader={<div className="w-32 h-6 rounded animate-pulse bg-gray-200" />}
											/>
											<DropdownMenuSeparator className="my-2" />
										</>
									)}
									<div className="flex flex-col space-y-1">
										<p className="text-sm font-medium leading-none">{data.me?.fullname}</p>
										<p className="text-xs leading-none text-muted-foreground">{data.me?.email}</p>
									</div>
								</DropdownMenuLabel>
								{isDesktop === false && displayMenu && (
									<>
										<DropdownMenuSeparator />
										{navbarDataMobile.map((item, index) => {
											return (
												<DropdownMenuItem key={index}>
													<Link
														from="/"
														onClick={() => {
															drp.onChange(false);
														}}
														to={item.to as any}
														className="text-sm text-slate-400 hover:text-slate-500"
														activeProps={{
															className: 'font-semibold text-slate-800 underline',
														}}
														activeOptions={{ exact: false, includeHash: true, includeSearch: false }}
														search={{
															...(item.search as any),
														}}
													>
														{item.label}
													</Link>
												</DropdownMenuItem>
											);
										})}
									</>
								)}
								<DropdownMenuSeparator />
								{isDesktop && (
									<>
										<DropdownMenuItem>
										<Link
											from="/"
											onClick={() => {
												drp.onChange(false);
											}}
											to='/dashboard/teams'
											className="text-sm text-slate-800"
											activeProps={{
												className: 'font-semibold text-slate-800 underline',
											}}
											activeOptions={{ exact: false, includeHash: true, includeSearch: false }}
											search={{
												pageNumber: 1,
												pageSize: 25,
												create: false,
											}}
										>
											Mon équipe
										</Link>
										</DropdownMenuItem>
										<DropdownMenuItem>
											<Link
												from="/"
												onClick={() => {
													drp.onChange(false);
												}}
												to='/dashboard/informations'
												className="text-sm text-slate-800"
												activeProps={{
													className: 'font-semibold text-slate-800 underline',
												}}
												activeOptions={{ exact: false, includeHash: true, includeSearch: false }}
												search={{
													activeTab: 'general',
												}}
											>
												Mes informations
											</Link>
										</DropdownMenuItem>
									</>
								)}
								
								<DropdownMenuItem
									onClick={() => {
										void requests.signout.signout().catch(console.error);
									}}
								>
									Se déconnecter
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)}
				</div>
			</div>
		</header>
	);
};
